import { graphql } from 'gatsby';
import React from 'react';
import Button from '~components/common/Button';
import Container from '~components/common/Container';
import Disclosure from '~components/common/Disclosure';
import FeatureList from '~components/common/FeatureList';
import ImageVideo from '~components/common/ImageVideo';
import Layout from '~components/layouts/Default';
import Cta from '~components/sections/Cta';
import PageHeader from '~components/sections/PageHeader';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';

function Page({ location, data }) {
  return (
    <Layout
      location={location}
      title="Zähneknirschen lindern mit der Muskelentspannung"
      desc="Die Progressive Muskelentspannung wirkt besonders gut gegen Zähneknirschen und Verspannungen. Sag dem Knirschen den Kampf an."
      active="Zähneknirschen"
      green
    >
      <PageHeader title="Zähneknirschen endlich loswerden [Mit 5 Quicktipps]" image={data.bildHeader} classNameImage="rounded-2xl mx-auto" wrapperClass="">
        <P className="mb-6">
          Der Schlaf vieler Menschen ist durch nächtliches Zähneknirschen gestört. Etwa jede dritte in Deutschland lebende Person leidet unter Bruxismus. Da das Knirschen, Pressen und Reiben mit den Zähnen nicht nur unangenehm, sondern auch
          teuer werden kann, erfährst du im Folgenden, was das lästige Knirschen auslöst und wie du es wieder loswirst.
        </P>

        <FeatureList
          items={[
            { text: 'Bruxismus - Was ist das?' },
            { text: 'Ursachen für Zähneknirschen' },
            { text: 'Betroffen? Der Schnelltest' },
            { text: 'Das hilft gegen Knirschen' },
            { text: 'Selbsterfahrung - Ein Einblick' },
            { text: 'Quicktipps zur sofortigen Anwendung' }
          ]}
          className="space-y-5"
        />
      </PageHeader>

      <Container className="text-brand-gray">
        <div className="mx-auto max-w-2xl space-y-8">
          <div>
            <H2 className="mb-5 font-display">Bruxismus - Was ist das?</H2>
            <P className="mb-3">
              Der medizinische Fachterminus für Reiben und Knirschen mit den Zähnen ist Bruxismus. Darunter werden sich rhythmisch wiederholende Bewegungen von Unter- und Oberkiefer zusammengefasst, die unwillkürlich ausgelöst werden.
              Während viele Menschen gerade nachts, beim Verarbeiten des Tages knirschen (Schlafbruxismus), sind andere auch tagsüber betroffen (Wachbruxismus). Dabei reiben und pressen Betroffene, insbesondere in konfliktbehafteten
              zwischenmenschlichen Situationen, mit den Zähnen.
            </P>
            <P className="mb-3">
              Bei ZahnmedizinerInnen werden zudem häufig Fragebögen oder spezielle Untersuchungen angewandt, um festzustellen, ob du unter Knirschen leidest. Eine erste Einschätzung kann dir aber auch der folgende Test geben.
            </P>
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1644420511/kunden/entspannungshelden/Z%C3%A4hneknirschen-Klitschko_opdjzs.mp4" image={data.videoCover} />
          </div>

          <div>
            <H2 className="mb-5 font-display">Ursachen für Zähneknirschen</H2>
            <P className="mb-3">
              Der Schlafbruxismus gilt mittlerweile als gut, aber nicht abschließend untersucht. Es wird davon ausgegangen, dass schlafbedingte Angststörungen, Schlafapnoe und Schnarchen in Zusammenhang mit Knirschen stehen. Auch durch
              übermäßigen Konsum von Alkohol, Koffein oder Rauchen wird das Knirschen vermutlich begünstigt.
            </P>
            <P>
              Egal ob du tags- oder nachtsüber knirschst, du kannst etwas dagegen tun! Damit du die optimale Methode für dich findest, um das Knirschen ein für alle Mal loszuwerden, ist es wichtig, die Ursache für dein Knirschen zu kennen.
              Nur so kannst du zielgerichtet handeln. Dabei muss es nicht immer die teure Botoxbehandlung oder sehr zeitaufwendige Physiotherapie sein. Doch dazu später mehr.
            </P>
          </div>

          <div>
            <H2 className="mb-5 font-display">Hauptursache für Zähneknirschen: Stress</H2>
            <P>
              Das Pressen der Zähne ist in vielen Fällen stressbedingt. Du kennst das Sprichwort, sich durch etwas durchbeißen. Hier ist es wörtlich gemeint. Erhöhter Druck im Alltag, stressige Lebens- oder Projektphasen erhöhen deine
              Kieferspannung. Wenn du schon einmal im Fitnessstudio trainiert hast, musstest du dich sicherlich schon einmal durch einen letzten Trainingssatz beißen und hast gepresst. Während im Fitnessstudio nur kurzzeitiger Stress durch
              das Gewicht entsteht, bleibt Stress aber häufig bestehen, da Arbeitsstress nach Feierabend in Freizeitstress übergeht. So wird deine Kaumuskulatur stets gefordert und trainiert. Während ein ausgewogenes Krafttraining deinen
              Körper fit hält, ist es beim Kiefer etwas anders.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Muskuläres Ungleichgewicht</H2>
            <P>
              Zu starke einseitige Belastung führt nämlich häufig zum Zähneknirschen oder Kieferschmerzen, durch Verspannungen oder eine Dysbalance. Häufig können Zahnärzte und Kieferorthopäden erkennen ob jemand knirscht, weil genau diese
              Muskeln stark ausgeprägt sind und einen breiten Kiefer machen. Zähne zusammenpressen erzeugt also Spannung in der Muskulatur, die es manchen Menschen unmöglich macht, den Mund weit zu öffnen, ohne starke Schmerzen knapp
              unterhalb der Ohren zu spüren. Spannung erzeugt Spannung, das heißt, dass sich ein Ungleichgewicht im Kiefer, auch sehr schnell auf deine Hals-, Nacken und Rückenpartie übertragen kann. So kommen manche Rückenschmerzen vom
              Kiefer, oder andersherum! Presst du häufig die Zähne aufeinander, solltest du die Spannungsverhältnisse deiner Kaumuskulatur abchecken lassen.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Aufbissprobleme und CMD</H2>
            <P>
              Manchmal sind die Probleme auch struktureller Natur, das ist dann der Fall, wenn dein Aufbiss nicht klappt, dein Kiefergelenk blockiert oder andere funktionelle Beschwerden vorliegen. Viele Zahnärzte sind darauf spezialisiert,
              Craniomandibuläre Dysfunktion (Überbegriff für Fehlregulation des Kiefergelenks) zu erkennen. Dadurch kann im weiteren Behandlungsverlauf durch Physiotherapie daran gearbeitet werden. Sehr häufig wird hierbei erst einmal die
              Beißschiene empfohlen, die zumindest kurzfristig einen schützenden Effekt hat.
            </P>
          </div>
          <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<Button to="/kurse/progressive-muskelentspannung-onlinekurs/" text="Progressive Muskelentspannung" />} withoutContainer />
          <div>
            <H2 className="mb-5 font-display">Zahnersatz</H2>
            <P className="mb-3">
              Tritt dein Knirschen oder vermehrte Kieferspannung hingegen erst kurzfristig auf, solltest du überprüfen, ob deine Füllungen und Zahnersatz noch in Ordnung sind. Denn manchmal entstehen Beschwerden auch durch kleine Änderungen
              in den Zahnreihen. Ein Besuch beim Zahnarzt kann dir hier Sicherheit geben und vielleicht bist du das Knirschen bald schon los. Übrigens: Frage aktiv nach, ob deine Zähne Anzeichen von Knirschen aufweisen, vornehmlich dann,
              wenn dein Kiefer sehr verspannt ist.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Grunderkrankungen</H2>
            <P>
              Kann vorher genanntes ausgeschlossen werden, solltest du deinen Lebensstil nach bestimmten Medikamenten, Alkohol und Kaffeekonsum hinterfragen, oder aber dein Augenmerk auf mögliche Erkrankungen legen. Leidest du nachts unter
              Restless-Legs, hast du Atemaussetzer, Durchblutungsstörungen oder ähnliches? Auch das kann knirschen begünstigen.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Bist du von Knirschen betroffen?</H2>
            <P className="mb-3">
              Einmal im Jahr solltest du bei deinem Zahnarzt auf dem Stuhl sitzen und deinen Kontrolltermin wahrnehmen. Dabei hast du die Gelegenheit, nach deinem Zahnschmelz und Veränderungen in deiner Mundschleimhaut gucken zu lassen. So
              fallen Beschädigungen schnell auf und du kannst ins Handeln kommen. Denn Knirschen kann, wie du gleich lesen wirst, sehr teuer werden.
            </P>
            <P className="mb-3">Du selbst hast aber auch die Möglichkeit deiner Kieferspannung zu prüfen, dafür kannst du dich auf die folgende Kurzentspannung einlassen:</P>
            <Disclosure title="Kurzentspannung für deinen Kiefer">
              <P className="mb-3">
                Schließe deine Augen, nimm einmal bewusst wahr, wie sich dein Körper gerade anfühlt. Bemerkst du innere Anspannung? Wie fühlen sich deine Muskeln deines Rückens und deiner Schulterblätter an? Wie angespannt ist deine Hals-
                und Nackenmuskulatur? Wie fühlt sich dein Kiefer an? Liegen deine Zähne zusammengepresst aufeinander? Oder hast du zwischen oberer und unterer Zahnreihe etwas Platz? Wo befindet sich deine Zunge? Vielleicht ist alles ganz
                locker und weich, vielleicht bemerkst du feste und verspannte Punkte.
              </P>
              <P>
                Lasse nun ganz bewusst all die Spannung aus deiner Muskulatur entweichen. Entspanne deinen Mund, sodass dein Unterkiefer leicht nach unten fällt und deine Zunge in den unten Mundraum fällt, lasse etwas Abstand zwischen den
                Zahnreihen und zaubere ein leichtes Lächeln auf deine Lippen. Wie fühlt sich das nun für dich an? Entspanne jetzt auch deinen Rücken. Deine Schultern fallen einfach nach unten, dein Kopf sinkt in eine bequeme Position. Nimm
                noch 1-2 bewusste Atemzüge und achte darauf, dass dein Mund auch weiterhin entspannt ist.{' '}
              </P>
            </Disclosure>
          </div>
          <div>
            <H2 className="mb-5 font-display">Zähneknirschen endlich loswerden</H2>
            <P className="mb-3">
              Die folgenden Methoden haben sich insbesondere bei Kieferbeschwerden bewährt. Wir zeigen dir auf, welche Vor- und Nachteile die jeweilige Behandlung hat, so kannst du am Ende entscheiden, was für dich am besten hilft und wie
              du vorgehen möchtest. Nach meinem Verständnis gibt es gegen Knirschen nicht <span className="font-bold">die eine Lösung</span>, sondern viel mehr unterschiedliche Zahnräder, die aufeinander abgestimmt werden dürfen.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Schmerzende Muskeln mit Botox lähmen</H2>
            <P className="mb-3">
              Neuerdings wird Botox eingesetzt und in entsprechend verspannte Kiefermuskeln injiziert, um diese zu entspannen. Das Nervengift soll eine Wirkung von 3 bis 6 Monaten haben, bevor sich bei unveränderten Lebensumständen der
              Spannungsgrad wieder erhöht. Die Behandlung ist mit 500 - 800 Euro sehr teuer und wird bislang von den wenigsten Krankenkassen übernommen. Aufgrund des Preisleistungsverhältnisses ist diese Methode nur bedingt zu empfehlen.
              Wahrscheinlich sagt auch dein gesunder Menschenverstand, dass eine bewusst herbeigeführte Betäubung oder gar Lähmung bestimmter Muskeln nicht die Lösung sein kann.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Aufbissschiene als Unterstützung</H2>
            <P className="mb-3">
              Eine schnelle und kurzfristige Hilfe ist die Aufbissschiene. Diese legst du dir vor dem Schlafen in den Mund und verhinderst durch die Kraftübertragung auf das gesamte Gebiss, einen starken Abrieb an betroffenen Stellen.
              Krankenkassen bezuschussen die Schiene in der Regel zu größten Teilen. Jedoch wird auch hier nur am Symptom selber behandelt und nicht an der Ursache. Weshalb die Schiene lediglich als Hilfsmittel angesehen werden kann, nicht
              als Heilmittel. Dennoch schwören auch einiger unserer Kursteilnehmer auf die Schiene. Also probiers aus!
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Manuelle Therapie als Therapieansatz</H2>
            <P className="mb-3">
              Haus- und Zahnärzte haben die Möglichkeit, „Manuelle Therapie“ zu verschreiben. Dabei handelt es sich um eine physiotherapeutische Anwendung, die passiven Strukturen und Gelenke manipuliert. Physiotherapeuten können mit
              bestimmten Handgriffen und Bewegungen zur Entlastung deiner Kiefermuskulatur beitragen. Besonders empfehlenswert ist hierbei die Kombination aus vorbereitender Wärme und der Manuellen Therapie. Die Behandlung dauert zwischen
              20 - 60 Minuten und beginnt mit dem Wärmeträger im Bereich der Nacken- und Kiefermuskulatur. Die wohltuende Wärme bereitet die Muskeln für die Behandlung vor. Pro Rezept fällt ein geringer Eigenanteil an und die Behandlung ist
              auf 6 - 10 Einheiten ausgelegt, so hast du ein gutes Preis-Leistungs-Verhältnis, zumindest so lange, wie die Ärzte verordnen.
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Lebensstil und Ursachenforschung</H2>
            <P className="mb-3">
              Durch meinen schlechten Lebensstil (Koffein, Alkohol, Stress) habe ich nachts geknirscht und so meinen Zahnschmelz beschädigt. Meine Lösung waren dann häufige Zahnarztbesuche und die Erneuerung meiner Füllungen. Da ich meinen
              Lebensstil aber nicht veränderte, blieb das Knirschen. Erst nachdem mein rechter, wurzelbehandelter Schneidezahn abbrach, wurde mir bewusst, wie stark mein Körper unter meiner Lebensweise litt. Da mein Bonusheft für
              Kontrollbesuche nur 3 statt 10 Stempel zählte, wurde ich so in 1,5 Jahren 3800 Euro für ein Implantat und Übergangskrone los. Man könnte sagen, es steckt ein Kleinwagen in meinem Kiefer. Deinen Lebensstil zu reflektieren kann
              ich dir also nur ans Herz legen, es lohnt sich und kostet erst einmal nichts!
            </P>
          </div>
          <div>
            <H2 className="mb-5 font-display">Progressive Muskelentspannung als Quicktipp</H2>
            <P className="mb-3">
              Die einfachste Methode gegen Knirschen ist wohl die Progressive Muskelentspannung nach Jacobson, das ich leider viel zu spät kennenlernte. Die Kombination aus Stressreduktion, das Erlernen von Entspannungsverfahren und der
              dadurch einhergehende neue Lebensstil verschafften mir schnell Linderung. Mit der Muskelentspannung kannst du gezielte Muskelgruppen anspannen und ermüden. Dadurch nimmst du Einfluss auf die Spannungszustände deiner Muskulatur
              und sorgst so ganz gezielt für Erleichterung.
            </P>
            <P className="mb-3">
              Als großer Fan der PME und Anwender der Meisterstufe, gebe ich mein Wissen und meine Erfahrungen in unserem zertifizierten Kursangebot: „Progressive Muskelentspannung Onlinekurs“ an dich weiter. So lernst du das Verfahren auch
              und das abgestimmt auf deinen Alltag. Das Beste ist, du bestimmst, wann und wo du die Videos des Kurses schaust und übst. Damit du ein Gefühl dafür bekommst, lade ich dich ein, die Abschlussübungen mitzumachen.
            </P>
          </div>
          <Cta headOne="Bist Du bereit für" headTwo="Entspannung?" button={<Button to="/kurse/progressive-muskelentspannung-onlinekurs/" text="Progressive Muskelentspannung" />} withoutContainer />

          <div>
            <dl className="mb-3 space-y-3">
              <Disclosure title="Mund zum O Formen">
                <P>
                  Öffne deinen Mund so weit wie du kannst und forme ein O mit deinen Lippen. Durch das Auseinanderziehen deiner Kiefermuskulatur knapp unterhalb deiner Ohren bemerkst du eine leichte Dehnung. Halte die Spannung für wenige
                  Sekunden und lasse dann wieder los.
                </P>
              </Disclosure>
              <Disclosure title="Mundwinkel verziehen">
                <P>Ziehe deine Mundwinkel zu deinen Ohren, entweder nach hinten oben oder unten. Drücke dabei deine Zähne angenehm aufeinander und presse die Zunge gegen deinen Gaumen, halte die Spannung für einen Moment und lasse los.</P>
              </Disclosure>
              <Disclosure title="Hamsterbacken">
                <P>
                  Plustere nun deinen Mund auf, als hättest du zwei Äpfel in deinen Backen versteckt. Halte deine Lippen geschlossen, sodass kein Geräusch entsteht. Nach wenigen Sekunden, lasse deinen Atem durch deine Lippen entweichen und
                  lasse wieder los.
                </P>
              </Disclosure>
              <Disclosure title="Druckpunkte pressen">
                <P>
                  Knapp unterhalb deiner Ohren sitzt ein starker Kiefermuskel. Wenn du diesen erfühlst, kannst du mit beiden Zeigefingern liebevoll hineindrücken und den Druck für ein paar Sekunden beibehalten. Bewege dabei gerne deinen
                  Unterkiefer leicht vor und zurück oder zur Seite, um den Muskel auszumassieren.
                </P>
              </Disclosure>
              <Disclosure title="Kieferschieben ">
                <P className="mb-3">
                  Diese Übung erfordert Achtsamkeit und Feingefühl. Schiebe deinen Kiefer vorsichtig in alle Richtungen, vor, zurück, nach rechts und links. Arbeite stets kontrolliert und nur in einem Bewegungsbereich, der dir guttut.
                </P>
              </Disclosure>
            </dl>
            <P className="mb-3">Du bist unsicher, ob der Kurs wirklich für dich geeignet ist? Dann mach zur Überprüfung doch unser Kursquiz und checke auch gleich, wie viel deine gesetzliche Krankenkasse von der Kursgebühr übernimmt.</P>

            <div className="mb-3">
              <Button text="Den richtigen Kurs finden" to="/kurse/kursquiz/" />
            </div>
            <Button text="Soviel zahlt deine Krankenkasse" to="/kurse/krankenkasse/" />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Page;

export const query = graphql`
  {
    bildHeader: file(relativePath: { eq: "pages/herausforderungen/zaehneknirschen/zaehneknirschen_header.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }

    videoCover: file(relativePath: { eq: "video/zaehneknirschen-klitschko.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
